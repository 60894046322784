body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: url("https://uploads-ssl.webflow.com/62e3ee10882dc50bcae8d07a/631a5d4631d4c55a475f3e34_noise-50.png");
  background-color: #16171b; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menuIcon::before {
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: 2px;
  border-radius: 50px;
  background: #fff;
  position: absolute;
  top: 9px;
  left: 5px;
  transition: 0.2s linear;
  transform-origin: center center;
}

.menuIcon::after {
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: 2px;
  border-radius: 50px;
  background: #fff;
  position: absolute;
  top: 14px;
  left: 5px;
  transition: all 0.2s linear;
  transform-origin: center center;
}

.menuIcon.active::before {
  transform: rotate(45deg);
  top: 11px;
}
.menuIcon.active::after {
  transform: rotate(-45deg);
  top: 11px;
}
