@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bounce {
  transition: all 0.3s ease-out;
}

.bounce:hover {
  display: inline-block;
  animation: rubberband 0.8s ease-out;
  color: #03ffa1;
}

@keyframes rubberband {
  0% {
    transform: scaleX(1);
  }
  40% {
    transform: scaleX(1.12) scaleY(0.75);
  }
  55% {
    transform: scaleX(0.85) scaleY(1);
  }
  65% {
    transform: scaleX(1.09) scaleY(0.85);
  }
  75% {
    transform: scaleX(0.9) scaleY(1);
  }
  90% {
    transform: scaleX(1.05) scaleY(0.95);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}

.tp-services-wrapper {
  padding-left: 65px;
}
@media (max-width: 767px) {
  .tp-services-wrapper {
    padding: 30px;
    padding-top: 50px;
  }
}

.tp-services-capsule-wrapper {
  position: relative;
  overflow: hidden;
  height: 630px;
  pointer-events: none;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .tp-services-capsule-wrapper {
    margin-right: 0;
  }
}
.tp-services-capsule-wrapper > .tp-services-capsule-item-wrapper > p {
  position: absolute;
  display: inline-block;
  margin-bottom: 0;
  left: 0;
  top: 0;
  user-select: none;
  pointer-events: auto;
  transition: none;
}
.tp-services-capsule-item {
  display: inline-block;
  font-size: 15px;
  color: black;
  font-weight: 500;
  border-radius: 100px;
  padding: 10px 26px;
  -webkit-transform: translate(-50%, -50%) rotate(0.01rad);
  -moz-transform: translate(-50%, -50%) rotate(0.01rad);
  -ms-transform: translate(-50%, -50%) rotate(0.01rad);
  -o-transform: translate(-50%, -50%) rotate(0.01rad);
  transform: translate(-50%, -50%) rotate(0.01rad);
  background: #03ffa1;
}

.noise {
  background: url("https://uploads-ssl.webflow.com/62e3ee10882dc50bcae8d07a/631a5d4631d4c55a475f3e34_noise-50.png");
}
